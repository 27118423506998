/*
  These are configuration options which can be public.

  ### Duplication On Purpose

  Do not put any sensitive keys in these files.  They *will* be seen by the end
  user if you do.  Only things an unauthenticated user is allowed to see.
  Anything else should go in lib/config.js and *never* import that file in any
  client/* files.

  That's why you'll see duplicate information found in the `secrets/config.json`
  (imported by `lib/config.js`).  Resist the programmer tendency to eliminate
  duplication and keep this file's information ___very___ separate from the
  information in `secrets/config.json`.

  The primary reason is I've found that claims from build tools to do "tree
  shaking" are mostly lies.  There's been plenty of times when `rollup` has
  imported entire modules when I've only used one function, included code that
  was purposefully excluded with a constant, and other errors.  Due to the
  unreliable nature of "tree shaking" I suggest you _always_ analyze your builds
  for extra junk being imported and purposefully trim down anything you find
  your build tool including on accident.

  For security though, you should never trust that "tree shaking" will work to
  exclude secret information. Always place private information in a completely
  separate file and then exclude that file in your `.gitignore` so it's never
  committed to your git repository.
 */

/*
  Should the payments be faked?  Look in `client/pages/Payment.svelte` for where this is checked and used.
 */
export const fake_payments = false;

/*
  The WebTorrent configuration to use.  This is passed to WebTorrent as configuration
  options to anything WebTorrent accepts should work.
 */
export const webtorrent = {
  use_dht: false,
  private: true
}

/*
  If you use [BTCPayServer](https://btcpayserver.org/) then this is the URL to the `btcpay.js` file
  they tell you to setup.  Also remember to set `disabled: false` in the `secrets/config.json` file.
 */
export const btcpay_url = 'https://pay.yoursite.com/modal/btcpay.js';


/*
  This simple stock demo project just sells one product, so you put the `id` here after you create
  it in the `product` table.
 */
export const product_id = 1;

/*
  The same `base_host` that you have in `secrets/config.json`.

  ___FOOTGUN___: This has to be duplicated since we want a _clear_ separation
  between ___private___ information in `secrets/` and public information in
  `client/`.
  */
export const base_host = "https://learncodethehardway.com"

/*
 * This is for the SEOTricks component.
 */
export const og_base_host = "https://learncodethehardway.com"

/*
  Used in emails and pages to tell people who to email.
 */
export const support_email = "help@learncodethehardway.com";

/*
  Your Paypal ___public___ configuration.

  ___FOOTGUN___: Yes, this is duplicated from `secrets/config.json` and you
  should duplicate it to ensure that you do ___not___ leak your Paypal private
  information in your client builds.
 */
export const paypal_public = {
	disabled: false,
	client_id: "Actrb7buL8HdYRLgdgyfZJYwEnjxiVN6ozMk63IIUVJdQCn1zlLybGL7-LpsmnJcww-V8a3NC4-rbN3i",
	disable_funding: ['credit', 'bancontact',
		'blik', 'eps', 'giropay', 'ideal', 'mybank',
		'p24', 'sepa', 'sofort', 'venmo'],
	debug: false
}


/*
  Your Stripe ___public___ configuration.

  ___FOOTGUN___: Yes, this is duplicated from `secrets/config.json` and you
  should duplicate it to ensure that you do ___not___ leak your Stripe private
  information in your client builds.
 */
export const stripe_public = {
  disabled: false,
  client_id: "pk_IevHTgtqYS6LWCI05u3V54he3tp5e",
}

/*
  What darkmode theme do visitors see first?  See `client/components/Darkmode.svelte`
  for how this is used.
 */
export const darkmode_default = "light";

/*
  Can people register on your site.  If you're setting up the site on an initial
  public server you might want to disable registration until it's working.
 */
export const register_enabled = true;

/*
  Default log levels to display in the browser console.
 */
export const log_levels = ["debug", "warn", "info", "error", "table", "assert"];

/*
  I'm using Twitter but you can probably find where this is used and change it
  to your favorite Twitter alternative.
 */
export const twitter_user = "@lzsthw";

/*
  Primarily used in the `commands/gen_players.js` command to generate various "cards", such as Video player cards for Twitter or OG Video cards for LinkedIn and Facebook.
 */
export const site_name = "Learn Code the Hard Way";

export default  {
  fake_payments, webtorrent, btcpay_url, product_id,
  og_base_host, support_email, paypal_public, stripe_public,
  darkmode_default,
  site_name, twitter_user, log_levels, register_enabled,
}
